/* You can add global styles to this file, and also import other style files */
@use "@angular/material" as mat;
@use "@triparc/nexus/src/styles-v1" as nex;
@use "@triparc/nexus/src/styles";

@import "base/grid";
@import "base/typography";
@import "layout/buttons";
@import "root/equalizer";
@import "utils/colors";
@import "utils/material-overrides";

html,
body {
  height: 100%;
}

@include nex.core();
// Create a theme with default palette, primary...warn, brands, states
$theme: nex.create-theme();

// Include all component theme
// Pass the theme to all components
@include nex.all-component-themes($theme);

//Temporal spinner due to nex spinner is not showing correctly
.spinner {
  border: 2px solid rgba(0, 0, 0, 0.1);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border-left-color: #69678c;

  animation: spin 1s ease infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
