@mixin make-gap($gap-y: 16px, $gap-x: 16px) {
  margin-top: calc(-1 * #{$gap-y});
  margin-right: calc(-0.5 * #{$gap-x});
  margin-left: calc(-0.5 * #{$gap-x});

  & > * {
    padding-right: calc(#{$gap-x} * 0.5);
    padding-left: calc(#{$gap-x} * 0.5);
    margin-top: $gap-y;
  }
}

$breakpoints: (
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1400,
) !default;

$cols: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12) !default;
$gap-y: 16px !default;
$gap-x: 16px !default;

.row {
  display: flex;
  flex-wrap: wrap;
}

.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
}

.row--gap-10 {
  @include make-gap(10px, 16px);
}

.col--gap-10 {
  @include make-gap(16px, 10px);
}

.row--gap-#{$gap-y} {
  @include make-gap($gap-y, 16px);
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

@each $col in $cols {
  .col-#{$col} {
    flex: 0 0 auto;
    width: calc(100% / 12) * $col;
  }
}

@each $bp, $size in $breakpoints {
  @media (min-width: #{$size}px) {
    .col-#{$bp}-auto {
      flex: 0 0 auto;
      width: auto;
    }

    @each $col in $cols {
      .col-#{$bp}-#{$col} {
        flex: 0 0 auto;
        width: calc(100% / 12) * $col;
      }
    }
  }
}

.end {
  text-align: end;
}
