@import "../utils/colors";

.button-label {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}


