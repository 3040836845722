// Fonts Poppins
@font-face {
  font-family: "Poppins";
  src: url("../../assets/fonts/Poppins/Poppins-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins-Medium";
  src: url("../../assets/fonts/Poppins/Poppins-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins-SemiBold";
  src: url("../../assets/fonts/Poppins/Poppins-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "Lora";
  src: url("../../assets/fonts/Lora/Lora-Regular.ttf") format("truetype");
}

body,
span:not(.material-symbols-outlined),
label,
li,
h4,
h6,
p,
figure,
blockquote,
dl,
dd {
  font-family: "Poppins" !important;
}

h5 {
  font-family: "Poppins-SemiBold" !important;
}

h1,
h2:not(.swal2-title),
h3 {
  font-family: "Lora" !important;
}

.subtitle-1 {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 22px;
  line-height: 29.7px;
}

.subtitle-2 {
  font-family: "Poppins";
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
}

.label-button {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}

.overline {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
}

.body-1 {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.body-2 {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.captions {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}

h1 {
  font-family: "Lora" !important;
  font-size: 57px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 64px;
  margin: 0;
}
h2:not(.swal2-title) {
  font-family: "Lora" !important;
  font-size: 45px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 52px;
  margin: 0;
}

h2:is(.swal2-title) {
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 32px;
  margin: 0;
}

h3 {
  font-family: "Lora" !important;
  font-size: 36px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 44px;
  margin: 0;
}
h4 {
  font-family: "Poppins" !important;
  font-size: 24px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 32px;
  letter-spacing: 0.32px !important;
  margin: 0;
}
h5 {
  font-family: "Poppins-SemiBold" !important;
  font-size: 28px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 36px;
  margin: 0;
}
h6 {
  font-family: "Poppins" !important;
  font-size: 24px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 32px;
  margin: 0;
}
